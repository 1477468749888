import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SantoStefanoFull from "../components/SantoStefanoFull"
import Form from "../components/form"

const SantoStefano = () => (
  <Layout>
    <SEO title="Veglione di San Silvestro - 26 Dicembre 2019" />
    <div className="custom-container evento">
      <div className="row">
        <div className="col-lg-5">
          <SantoStefanoFull />
        </div>
        <div className="col-lg-7">
          <h1>Veglione di Santo Stefano</h1>
          <h4>26 Dicembre 2019</h4>
          <section className="contatti">
            <Form formTitle="Prenora Ora" />
          </section>
        </div>
      </div>
    </div>
  </Layout>
)

export default SantoStefano